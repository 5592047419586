<template>
  <div>
    <a-row type="flex" justify="center" style="margin-top: -200px">
      <a-col :span="23" :lg="16">
        <a-row
          style="
            background-color: #ffffff;
            box-shadow: 0px 0px 17px -8px rgba(14, 155, 210, 20);
            border-radius: 8px;
          "
          :style="{ padding: fullWidth > 992 ? '20px 30px' : '20px 10px' }"
        >
          <a-col :span="24">
            <el-page-header
              style="
                width: 100%;
                height: 50px;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-left: 10px;
              "
              @back="backLegalHome"
              content="答题记录"
            >
            </el-page-header>
            <!-- <div style="display: flex; align-items: center">
              <div
                style="
                  flex: 1;
                  text-align: left;
                  font-size: 18px;
                  color: #333333;
                  font-weight: bold;
                  margin-bottom: 20px;
                "
              >
                答题记录
              </div>
            </div> -->
            <template v-if="empty">
              <a-row
                type="flex"
                justify="center"
                style="margin-bottom: 10px; margin-top: 10px; min-height: 400px"
              >
                <el-empty :description="description"></el-empty>
              </a-row>
            </template>
            <template v-else>
              <a-row
                :gutter="24"
                style="min-height: 500px"
                v-loading="loading"
                element-loading-text="加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.2)"
              >
                <a-col
                  :span="12"
                  :lg="6"
                  v-for="(item, index) in answerRecordList"
                  :key="item.classRoomId"
                  @click="onItemClick(index)"
                >
                  <div
                    style="
                      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.09);
                      border-radius: 8px;
                      overflow: hidden;
                      margin-bottom: 24px;
                      cursor: pointer;
                    "
                  >
                    <div style="padding: 8px">
                      <div
                        class="u-line-2"
                        style="
                          font-size: 14px;
                          color: #101010;
                          font-weight: 400;
                          height: 36px;
                          line-height: 36px;
                          margin-bottom: 8px;
                        "
                      >
                        {{ item.competitionName }}
                      </div>
                      <div class="course-cate">
                        <div
                          class="mt-8"
                          style="
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            margin-top: 0;
                            margin-bottom: 10px;
                          "
                        >
                          <div
                            style="
                              font-size: 12px;
                              color: #0e9bd2;
                              background-color: #ddf6ff;
                              border: 1px solid #0e9bd2;
                              border-radius: 4px;
                              padding: 2px 10px;
                              margin-right: 5px;
                            "
                          >
                            {{ item.okCount }}/{{ item.totalCount }}题
                          </div>
                        </div>
                      </div>
                      <div
                        class="mt-8"
                        style="
                          display: flex;
                          align-items: center;
                          margin-bottom: 8px;
                          justify-content: space-between;
                        "
                      >
                        <div
                          style="
                            font-size: 12px;
                            color: #a8a8a8;
                            word-break: keep-all;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                          "
                        >
                          {{ item.updateDate }}
                        </div>
                        <div
                          style="
                            font-size: 12px;
                            color: #a8a8a8;
                            word-break: keep-all;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                          "
                        >
                          得分{{ item.totalScore }}
                        </div>
                      </div>
                    </div>
                  </div>
                </a-col>
              </a-row>
            </template>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  created() {
    this.getAnswerRecord();
  },
  data() {
    return {
      answerRecordList: [],
      loading: true,
      empty: false,
    };
  },
  computed: {
    ...mapState(["fullWidth"]),
  },
  methods: {
    getAnswerRecord() {
      this.$HTTP
        .httpToken({
          url: "/classroom/getCompetitionAnswerRecodeList",
          method: "post",
          data: {},
        })
        .then((res) => {
          console.log("答题记录", res);
          this.loading = false;
          if (res.code == "0" && res.data && res.data.length > 0) {
            this.empty = false;
            this.answerRecordList = res.data;
          } else {
            this.empty = true;
            this.description = "暂无数据";
          }
        })
        .catch((e) => {
          this.loading = false;
          this.description = e;
          this.empty = false;
        });
    },
    backLegalHome() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>